import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Routes, Switch } from 'react-router-dom';
import PublicRoute from '../../components/publicRoute';
import PrivateRoute from '../../components/privateRoute';
import Spinner from '../../components/spinner';
import PageNotFound from '../../components/pageNotFound';

const Signin = lazy(() => import('../login/signin/index'));
const Signup = lazy(() => import('../login/signup/index'));
const ErrorPage = lazy(() => import('../../components/error'));
const ForgotPassword = lazy(() => import('../login/forgotPassword/index'));
const ResetPassword = lazy(() => import('../login/resetPassword/index'));
const Subscription = lazy(() => import('../subscription'));
const Listview = lazy(() => import('../dashboard'));
const Gridview = lazy(() => import('../dashboard'));

const Router = () => (
  <Routes basename="/login">
    <Suspense fallback={<Spinner />}>
      <Switch>
        <PublicRoute path="/" exact component={Signin} />
        <PublicRoute path="/sign-up" component={Signup} />
        <PublicRoute path="/forgot-password" component={ForgotPassword} />
        <PublicRoute path="/reset-password" component={ResetPassword} />
        <PublicRoute path="/create-password" component={ResetPassword} />
        <PublicRoute path="/update-password" component={ResetPassword} />
        <PublicRoute path="/error" component={ErrorPage} />
        <PrivateRoute path="/grid-view" component={Gridview} header footer />
        <PrivateRoute path="/list-view" component={Listview} header footer />
        <PrivateRoute path="/subscription" component={Subscription} header footer />
        <PublicRoute component={PageNotFound} />
      </Switch>
    </Suspense>
  </Routes>
);
export default Router;
