/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

// eslint-disable-next-line import/no-mutable-exports
let config = null;
if (process && process.env && process.env.NODE_ENV) {
  // TO DO DYNAMIC IMPORT
  if (process.env.NODE_ENV === 'development') {
    config = require('./env/development');
  } else if (process.env.NODE_ENV === 'staging') {
    config = require('./env/stage');
  } else {
    config = require('./env/production');
  }
} else {
  config = require('./env/development');
}

module.exports = config;
