export const USER_RESET_PASSWORD_REQUEST = 'users/USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_REQUEST_FAIL = 'users/USER_RESET_PASSWORD_REQUEST_FAIL';
export const USER_RESET_PASSWORD_RESPONSE = 'users/USER_RESET_PASSWORD_RESPONSE';
export const USER_RESET_PASSWORD_LINK_SHOW = 'users/USER_RESET_PASSWORD_LINK_SHOW';
export const USER_RESET_PASSWORD_LINK_HIDE = 'users/USER_RESET_PASSWORD_LINK_HIDE';
export const USER_RESET_PASSWORD_PASSWORDTYPE = 'users/USER_RESET_PASSWORD_PASSWORDTYPE';
export const USER_RESET_PASSWORD_CONFIRM_PASSWORDTYPE = 'users/USER_RESET_PASSWORD_CONFIRM_PASSWORDTYPE';
export const USER_RESET_PASSWORD_LINK_MESSAGE = 'users/USER_RESET_PASSWORD_LINK_MESSAGE';
export const USER_RESET_PASSWORD_CURRENT_PASSWORDTYPE = 'users/USER_RESET_PASSWORD_CURRENT_PASSWORDTYPE';
export const USER_RESET_CONFIRM_PASSWORD_FOCUS = 'users/USER_RESET_CONFIRM_PASSWORD_FOCUS';
export const USER_RESET_CONFIRM_PASSWORD_BLUR = 'users/USER_RESET_CONFIRM_PASSWORD_BLUR';
