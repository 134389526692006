export default {
  apiBaseURL: 'https://secureapi.albertsonsprintcenter.com/',
  cdnURL: 'https://securecdn.albertsonsprintcenter.com/',
  siteUrl: 'https://albertsonsprintcenter.com/login/',
  revshop_site_url: 'https://albertsonsprintcenter.com',
  user_register_url: "/BuyerRegistration",
  apiVersionPath: 'api/v1/',
  password_length: 6,
  cryptoJs: {
    password: '12wgh45q',
    separator: '~',
    pathSeparator: '/',
  },
  captchaKey: '6Lc5fZUUAAAAABDiH1AVGGTFIkeNCu4628fUEwmg', // server albertsonsprintcenter.com/login/
  // captchaKey: '6Ld5p5AUAAAAAPUkK8SknWJRYqBu-AvDzsGN5Ubz', // server dev.revshop.com/login/
  // captchaKey: '6Le7ZJEUAAAAAKE21PBKtIdaT2F7ROEk-y3gYUrA', // server devbeta.revshop.com/login
};
