import React from 'react';
import Config from '../config';

const Spinner = () => (
  <div className="login-section ig-spinner">
    <div className="container">
      <div className="col-lg-4 col-md-7 col-sm-8 login-form col-centered">
        <div className="text-center">
          <img src={`${Config.cdnURL}img/spinner-loop.gif`} style={{ width: '350px', height: '250px' }} alt="Aximsoft" className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
);

export default Spinner;
