import * as actionTypes from '../../actions/actionTypes/forgotPassword';

const initialState = {
  email: '',
  isFetching: false,
  error: '',
  message: '',
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_FORGOT_PASSWORD_REQUEST:
      return Object.assign({}, prevState, { isFetching: true });
    case actionTypes.USER_FORGOT_PASSWORD_CLEAR:
      return Object.assign({}, prevState, { isFetching: false, error: initialState.error });
    case actionTypes.USER_FORGOT_PASSWORD_REQUEST_FAIL:
      return Object.assign({}, prevState, { isFetching: false, error: action.error });
    case actionTypes.USER_FORGOT_PASSWORD_RESPONSE:
      // eslint-disable-next-line max-len
      return Object.assign({}, prevState, { isFetching: false, message: action.data, error: initialState.error });
    default:
      return prevState;
  }
};
