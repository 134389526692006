import * as actionTypes from '../../actions/actionTypes/subscription';

const initialState = {
  isFetching: false,
  error: '',
  message: '',
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_SUBSCRIPTION_REQUEST:
      return Object.assign({}, prevState, { isFetching: true });
    case actionTypes.PRODUCT_SUBSCRIPTION_REQUEST_FAIL:
      return Object.assign({}, prevState, { isFetching: false, error: action.error });
    case actionTypes.PRODUCT_SUBSCRIPTION_RESPONSE:
      return Object.assign({}, prevState, { isFetching: false, message: action.data });
    default:
      return prevState;
  }
};
