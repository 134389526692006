import * as types from '../../actions/actionTypes/auth';

const initialState = {
  isFetching: false,
  error: '',
  jwt: '',
  isAuthenticated: false,
  isorganization: false,
  organizationOption: [],
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case types.AUTH_REQUEST:
      return Object.assign({}, prevState, { isFetching: true });
    case types.AUTH_CLEAR:
      return Object.assign({}, prevState, { isFetching: false, error: initialState.error });
    case types.AUTH_REQUEST_FAIL:
      return Object.assign({}, prevState, { isFetching: false, error: action.error });
    case types.AUTH_RESPONSE:
      // eslint-disable-next-line max-len
      return Object.assign({}, prevState, { isFetching: false, isAuthenticated: true, jwt: action.data.jwt });
    case types.AUTH_SIGNOUT:
      return Object.assign({}, prevState, { isAuthenticated: false });
    case types.AUTH_RESPONSE_ORGANIZATION_ID:
      // eslint-disable-next-line max-len
      return Object.assign({}, prevState, { isAuthenticated: false, isorganization: true, organizationOption: action.data.data, error: initialState.error });
    case types.AUTH_RESPONSE_ORGANIZATION_ID_FAIL:
      // eslint-disable-next-line max-len
      return Object.assign({}, prevState, { isAuthenticated: false, isorganization: false, organizationOption: [] });
    default:
      return prevState;
  }
};
