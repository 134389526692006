export default {
  apiBaseURL: 'http://localhost:3003/',
  cdnURL: 'http://localhost:8085/',
  apiVersionPath: 'api/v1/',
  siteUrl: 'http://localhost:3000/',
  revshop_site_url: 'http://albertsonsprintcenter.com',
  user_register_url: "/BuyerRegistration",
  password_length: 6,
  captchaKey: '6Lc_6YgUAAAAAGEUBtvmDPBTsK3DdD8cshEdaMmb', // Local
  cryptoJs: {
    password: '12wgh45q',
    separator: '~',
    pathSeparator: '/',
  },
};
