import { takeEvery, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiCall } from '../../util';
import * as userActionTypes from '../../actions/actionTypes/resetPassword';
import { userResetPasswordResponse, userResetPasswordRequestFail } from '../../actions/users/resetPassword';

function* sendResetPasswordRequest(userDetails) {
  if (userDetails.payload.updatePassword) {
    const response = yield apiCall('resetpassword', 'POST', userDetails);
    if (response.status !== 200) {
      yield put(userResetPasswordRequestFail(response.data.error));
    } else {
      yield put(userResetPasswordResponse(response.data.message));
    }
  } else {
    const response = yield apiCall('resetpassword', 'POST', userDetails);
    if (response.status !== 200) {
      yield put(userResetPasswordRequestFail(response.data.error));
    } else {
      yield put(userResetPasswordResponse(response.data.message));
      yield (delay(5000));
      yield put(userResetPasswordResponse(''));
      userDetails.props.history.push('/');
    }
  }
}

export function* takeResetPasswordRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.USER_RESET_PASSWORD_REQUEST,
    sendResetPasswordRequest,
  );
}
