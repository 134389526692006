import * as actionTypes from '../../actions/actionTypes/resetPassword';

const initialState = {
  email: '',
  isFetching: false,
  error: '',
  message: '',
  isModal: true,
  modalMessage: false,
  passwordType: 'password',
  confirmPasswordType: 'password',
  currentPasswordType: 'password',
  confirmPasswordFocus: false,
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_RESET_PASSWORD_REQUEST:
      return Object.assign({}, prevState, { isFetching: true });
    case actionTypes.USER_RESET_PASSWORD_REQUEST_FAIL:
      return Object.assign({}, prevState, { isFetching: false, error: action.error });
    case actionTypes.USER_RESET_PASSWORD_RESPONSE:
      // eslint-disable-next-line max-len
      return Object.assign({}, prevState, { isFetching: false, message: action.data, error: initialState.error });
    case actionTypes.USER_RESET_PASSWORD_LINK_SHOW:
      return Object.assign({}, prevState, { isFetching: false, isModal: false });
    case actionTypes.USER_RESET_PASSWORD_LINK_HIDE:
      return Object.assign({}, prevState, { isFetching: false, isModal: true });
    case actionTypes.USER_RESET_PASSWORD_LINK_MESSAGE:
      return Object.assign({}, prevState, { isFetching: false, modalMessage: true });
    case actionTypes.USER_RESET_PASSWORD_PASSWORDTYPE:
      return Object.assign({}, prevState, { isFetching: false, passwordType: prevState.passwordType === 'password' ? 'input' : 'password' });
    case actionTypes.USER_RESET_PASSWORD_CONFIRM_PASSWORDTYPE:
      return Object.assign({}, prevState, { isFetching: false, confirmPasswordType: prevState.confirmPasswordType === 'password' ? 'input' : 'password' });
    case actionTypes.USER_RESET_PASSWORD_CURRENT_PASSWORDTYPE:
      return Object.assign({}, prevState, { isFetching: false, currentPasswordType: prevState.currentPasswordType === 'password' ? 'input' : 'password' });
    case actionTypes.USER_RESET_CONFIRM_PASSWORD_FOCUS:
      return Object.assign({}, prevState, { isFetching: false, confirmPasswordFocus: true });
    case actionTypes.USER_RESET_CONFIRM_PASSWORD_BLUR:
      return Object.assign({}, prevState, { isFetching: false, confirmPasswordFocus: false });
    default:
      return prevState;
  }
};
