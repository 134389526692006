import { takeEvery, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiCall } from '../../util';
import Config from '../../config';
import * as userActionTypes from '../../actions/actionTypes/forgotPassword';
import { userForgotPasswordResponse, userForgotPasswordRequestFail } from '../../actions/users/forgotPassword';

function* sendForgotPasswordRequest(userDetails) {
  const userCallback = { callbackurl: `${Config.siteUrl}reset-password` };
  Object.assign(userDetails.payload, userCallback);
  const response = yield apiCall('forgotpassword', 'POST', userDetails);
  if (response.status !== 200) {
    yield put(userForgotPasswordRequestFail(response.data.error));
  } else {
    yield put(userForgotPasswordResponse(response.data.message));
    yield (delay(5000));
    yield put(userForgotPasswordResponse(''));
    userDetails.props.history.push('/');
  }
}

export function* takeForgotPasswordRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.USER_FORGOT_PASSWORD_REQUEST,
    sendForgotPasswordRequest,
  );
}
