
import React from 'react';

const Footer = () => (
  <div className="footer axim-version">
    Copyright © 2019 All Rights Reserved.
    {' '}
    <span className="version">version</span>
  </div>
);

export default Footer;
