import { takeEvery, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiCall } from '../../util';
import * as userActionTypes from '../../actions/actionTypes/signup';
import { userProfileRequestFail, userProfileResponse } from '../../actions/users/signup';

function* sendUserProfileRequest(payload) {
  const userDetails = { ...payload, subscription: null };
  const response = yield apiCall('signup', 'POST', userDetails);
  if (response.status !== 200) {
    yield put(userProfileRequestFail(response.data.error));
  } else {
    yield put(userProfileResponse(response.data.message));
    yield (delay(3000));
    userDetails.props.history.push('/');
  }
}

export function* takeSignupRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.USER_PROFILE_REQUEST,
    sendUserProfileRequest,
  );
}
