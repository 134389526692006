import axios from 'axios';
import config from '../config';

const getHeaders = () => {
  const jwt = localStorage.getItem('JWT') || '';
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (jwt) {
    headers.Authorization = jwt;
  }
  return headers;
};
const handleErrors = (res, request) => {
  if (res.status === undefined) {
    res.error = 'Failed to load resource';
    request.props.history.push('/error');
  }
  switch (res.status) {
    case 401: // unauthorised
    case 403: // forbidden
    case 400: // bad request
      request.props.history.push('/error');
      break;
    default:
      return res;
  }
  return res;
};
export const apiCall = async (apiPath, action, data) => {
  try {
    const rootPath = ['auth', 'signup', 'forgotpassword', 'resetpassword'];
    const baseUrl = rootPath.includes(apiPath) ? `${config.apiBaseURL}` : `${config.apiBaseURL}${config.apiVersionPath}`;
    return await axios({
      url: `${baseUrl}${apiPath}`,
      method: action,
      data: data.payload,
      headers: getHeaders(),
      validateStatus(status) {
        return status >= 200 && status <= 500;
      },
    })
      .then(res => res)
      .catch(err => handleErrors(err, data));
  } catch (ex) {
    console.log(ex);
    return false;
  }
};
