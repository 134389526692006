import * as actionTypes from '../actionTypes/subscription';

export function productSubscriptionRequest(payload, props) {
  return { type: actionTypes.PRODUCT_SUBSCRIPTION_REQUEST, payload, props };
}

export function productSubscriptionRequestFail(error) {
  return { type: actionTypes.PRODUCT_SUBSCRIPTION_REQUEST_FAIL, error };
}

export function productSubscriptionResponse(data) {
  return { type: actionTypes.PRODUCT_SUBSCRIPTION_RESPONSE, data };
}
