import * as actionTypes from '../actionTypes/auth';

export function authRequest(payload, props) {
  return { type: actionTypes.AUTH_REQUEST, payload, props };
}
export function authRequestFail(error) {
  return { type: actionTypes.AUTH_REQUEST_FAIL, error };
}

export function authResponse(data) {
  return { type: actionTypes.AUTH_RESPONSE, data };
}

export function authOrganizationFail(data) {
  return { type: actionTypes.AUTH_RESPONSE_ORGANIZATION_ID_FAIL, data };
}

export function authOrganizationResponse(data) {
  return { type: actionTypes.AUTH_RESPONSE_ORGANIZATION_ID, data };
}
export function authSignout(props) {
  return { type: actionTypes.AUTH_SIGNOUT, props };
}
export function authClear(props) {
  return { type: actionTypes.AUTH_CLEAR, props };
}
