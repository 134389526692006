import { takeEvery, put } from 'redux-saga/effects';
import { apiCall } from '../../util';
import * as actionTypes from '../../actions/actionTypes/subscription';
import { productSubscriptionRequestFail } from '../../actions/subscription';

function* productActiveSubscriptionRequest(subscriptionDetails) {
  const response = yield apiCall('user/subscription', 'POST', subscriptionDetails);
  if (response && response.status) {
    if (response.status !== 200) {
      yield put(productSubscriptionRequestFail(response.data.error));
      subscriptionDetails.props.history.push('/');
    } else {
      subscriptionDetails.props.history.push('/list-view');
    }
  }
}

export function* takeSubscriptionRequest(): Generator<*, *, *> {
  yield takeEvery(
    actionTypes.PRODUCT_SUBSCRIPTION_REQUEST,
    productActiveSubscriptionRequest,
  );
}
