import React from 'react';
import { Link } from 'react-router-dom';
import Connect from '../../../components/ConnectWithLifeCycle';
import { authSignout } from '../../../actions/auth';

const Header = props => (
  <Connect>
    {({
      dispatch,
    }) => (
      <div className="header">
        <nav className="navbar navbar-expand-md bg-light navbar-light justify-content-between fixed-top">
          <div className="navbar-brand col-centered" />
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <div className="nav-link dropdown-toggle" id="navbardrop" data-toggle="dropdown">
                <i className="fa fa-user fa-2x" />
              </div>
              <div className="dropdown-menu dropdown-menu-right">
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => {
                    dispatch(authSignout(props));
                  }}
                >
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    )}
  </Connect>
);

export default Header;
