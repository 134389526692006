import * as actionTypes from '../../actions/actionTypes/signup';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  isFetching: false,
  error: '',
  captcha: '',
  message: '',
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_PROFILE_REQUEST:
      return Object.assign({}, prevState, { isFetching: true });
    case actionTypes.USER_PROFILE_REQUEST_FAIL:
      return Object.assign({}, prevState, { isFetching: false, error: action.error });
    case actionTypes.USER_PROFILE_RESPONSE:
      return Object.assign({}, prevState, { isFetching: false, message: action.message });
    default:
      return prevState;
  }
};
