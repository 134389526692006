import { all, fork } from 'redux-saga/effects';
import { takeLoginRequest, takeLogoutRequest } from './auth';
import { takeSignupRequest } from './users/signup';
import { takeForgotPasswordRequest } from './users/forgotPassword';
import { takeResetPasswordRequest } from './users/resetPassword';
import { takeSubscriptionRequest } from './subscription';

function* root() {
  yield all([
    fork(takeLoginRequest),
    fork(takeLogoutRequest),
    fork(takeSignupRequest),
    fork(takeForgotPasswordRequest),
    fork(takeResetPasswordRequest),
    fork(takeSubscriptionRequest),
  ]);
}

export default root;
