import { takeEvery, put } from 'redux-saga/effects';
import { apiCall } from '../../util';
// eslint-disable-next-line import/no-unresolved
import config from '../../config';
import * as actionTypes from '../../actions/actionTypes/auth';
import { authRequestFail, authResponse, authOrganizationResponse, authOrganizationFail } from '../../actions/auth';

function* sendAuthRequest(authDetails) {
  // if (organization.value) authDetails.orgId = organisation.value
  const response = yield apiCall('auth', 'POST', authDetails);
  if (response.status !== 200) {
    yield put(authRequestFail(response.data.error));
    yield put(authOrganizationFail());
  } else {
    const json = yield response.data;
    if (response.data && response.data.count > 1) {
      yield put(authOrganizationResponse({ ...json }));
    } else {
      window.localStorage.setItem('JWT', json.data.jwt);
      global.jwt = json.data.jwt;
      yield put(authResponse({ ...json }));
      yield put(authRequestFail(''));
      const queryString = `?cid=${json.data.contact_id}`;
      const form = document.getElementById('signinForm');
      json.data.defaultpagename = (json.data.defaultpagename.indexOf('/') > -1) ? json.data.defaultpagename : `/${json.data.defaultpagename}`;
      form.action = `${config.revshop_site_url}${json.data.defaultpagename}${queryString}`;
      // form.action = `${config.revshop_site_url}`;
      form.method = 'POST';
      document.getElementById('txtToken').value = json.data.jwt;
      form.submit();
    }
  }
}

function* sendAuthSignOut(authDetails) {
  yield put(authResponse(authDetails));
  global.jwt = '';
  localStorage.clear();
  authDetails.props.history.push('/');
}

export function* takeLoginRequest(): Generator<*, *, *> {
  yield takeEvery(
    actionTypes.AUTH_REQUEST,
    sendAuthRequest,
  );
}

export function* takeLogoutRequest(): Generator<*, *, *> {
  yield takeEvery(
    actionTypes.AUTH_SIGNOUT,
    sendAuthSignOut,
  );
}
