import * as actionTypes from '../actionTypes/resetPassword';

export function userResetPasswordRequest(payload, props) {
  return { type: actionTypes.USER_RESET_PASSWORD_REQUEST, payload, props };
}

export function userResetPasswordRequestFail(error) {
  return { type: actionTypes.USER_RESET_PASSWORD_REQUEST_FAIL, error };
}

export function userResetPasswordResponse(data) {
  return { type: actionTypes.USER_RESET_PASSWORD_RESPONSE, data };
}


export function userResetPasswordLinkShow(data) {
  return { type: actionTypes.USER_RESET_PASSWORD_LINK_SHOW, data };
}
export function userResetPasswordLinkHide(data) {
  return { type: actionTypes.USER_RESET_PASSWORD_LINK_HIDE, data };
}
export function userResetPasswordLinkMessage(data) {
  return { type: actionTypes.USER_RESET_PASSWORD_LINK_MESSAGE, data };
}
export function userPasswordType(data) {
  return { type: actionTypes.USER_RESET_PASSWORD_PASSWORDTYPE, data };
}
export function userConfirmPasswordType(data) {
  return { type: actionTypes.USER_RESET_PASSWORD_CONFIRM_PASSWORDTYPE, data };
}
export function userCurrentPasswordType(data) {
  return { type: actionTypes.USER_RESET_PASSWORD_CURRENT_PASSWORDTYPE, data };
}
export function userConfirmPasswordTypeFocus(data) {
  return { type: actionTypes.USER_RESET_CONFIRM_PASSWORD_FOCUS, data };
}
export function userConfirmPasswordTypeBlur(data) {
  return { type: actionTypes.USER_RESET_CONFIRM_PASSWORD_BLUR, data };
}