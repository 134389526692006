import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import authReducers from './auth';
import userReducers from './users/signup';
import forgotPasswordReducer from './users/forgotPassword';
import resetPasswordReducer from './users/resetPassword';
import subscriptionReducer from './subscription';

const reducer = combineReducers({
  user: userReducers,
  forgotpassword: forgotPasswordReducer,
  resetpassword: resetPasswordReducer,
  subscription: subscriptionReducer,
  auth: authReducers,
  form: reduxFormReducer,
});
export default reducer;
