import React from 'react';
import { Route } from 'react-router-dom';
import Header from '../containers/router/header';
import Footer from '../containers/router/footer';

const PublicRoute = ({ component: Component, header, footer, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <div>
        {header ? <Header /> : ''}
        <Component {...props} />
        {footer ? <Footer /> : ''}
      </div>
    )}
  />
);

export default PublicRoute;
